/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import languageProviderReducer from 'providers/LanguageProvider/data/state';
import { chatsApi } from 'fsd/entities/Chats/api/chatsApi';
import { purchaseApi } from 'fsd/entities/PurchasePage/api/purchaseApi';
import businessReducer from 'business/pages/BusinessProfile/api/businessSlice';
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    language: languageProviderReducer,
    [chatsApi.reducerPath]: chatsApi.reducer,
    [purchaseApi.reducerPath]: purchaseApi.reducer,
    business: businessReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  return rootReducer;
}
