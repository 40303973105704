import React from 'react';
import { Link } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component/dist/react-stars';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';

const BusinessNameProduct = ({ product, businessName }) => {
  const classes = useStyles();
  return (
    <Box className={classes.businessNameProduct}>
      <Link
        to={`/b/${
          product?.user?.link ? product?.user?.link : product?.user?.business
        }`}
      >
        {businessName}
      </Link>
      <ReactStars
        edit={false}
        half={true}
        value={product?.rating}
        size={12}
      />
    </Box>
  );
};

export default React.memo(BusinessNameProduct);
