import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Link } from 'react-router-dom';

// Mui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import GoogleSignIn from 'containers/GoogleSignIn';
import LoadingButton from 'components/LoadingButton';
import commonMessages from 'messages/Common';
import { useStyles } from './styles';
import { loginSchema } from './schema';
import messages from 'messages/Auth';
import {
  checkLogin,
  hasErrorPassword,
  isEmail,
  onInputCheckPassword,
} from './validation/validation';

const initValues = {
  name: '',
  login: '',
  password: '',
  confirmPassword: '',
};

function RegisterComponent(props) {
  const classes = useStyles();

  const { onSubmit, setType, intl, onClose } = props;

  const [showPass, setShowPass] = useState(false);

  const [state, setState] = useState({
    isValid: false,
    values: initValues,
    startValues: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(state.values, loginSchema);

    setState((prevState) => ({
      ...prevState,
      isValid: !errors,
      errors: errors || {},
    }));

    return () => null;
  }, [state.values]);

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]:
          name === 'password' ? value.replace(onInputCheckPassword, '') : value,
      },
      touched: {
        ...prevState.touched,
        [name]: true,
      },
    }));
    if (name === 'login') {
      localStorage.setItem('tezsatRecoveryPhone', value);
    }
  };

  const hasError = (field) => !!(state.touched[field] && state.errors[field]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.loginRoot}>
      <form
        onSubmit={(e) => onSubmit(e, state.values)}
        className={classes.form}
      >
        <TextField
          variant="outlined"
          margin="dense"
          placeholder={intl.formatMessage({ ...messages.name })}
          name="name"
          value={state.values.name}
          error={hasError('name')}
          helperText={
            hasError('name')
              ? intl.formatMessage({ ...commonMessages[state.errors.name[0]] })
              : null
          }
          onChange={handleChange}
          inputProps={{ maxLength: 50 }}
        />

        <TextField
          variant="outlined"
          margin="dense"
          placeholder={intl.formatMessage({ ...messages.login })}
          name="login"
          value={state.values.login}
          error={
            checkLogin.test(state.values.login)
              ? false
              : state?.touched?.login
              ? true
              : false
          }
          helperText={
            !state?.touched?.login ||
            checkLogin.test(state.values.login) ? null : (
              <Typography
                className={classes.extraTextPassword}
                variant="caption"
              >
                {isEmail.test(state.values.login)
                  ? intl.formatMessage({ ...messages.wrongEmail })
                  : intl.formatMessage({ ...messages.phoneFormat })}
              </Typography>
            )
          }
          onChange={handleChange}
        />

        <TextField
          type={showPass ? 'text' : 'password'}
          variant="outlined"
          margin="dense"
          name="password"
          color="primary"
          placeholder={intl.formatMessage({ ...messages.password })}
          value={state.values.password}
          error={
            hasErrorPassword.test(state.values.password)
              ? false
              : state?.touched?.password
              ? true
              : false
          }
          onChange={handleChange}
          helperText={
            state?.touched?.password &&
            hasErrorPassword.test(state.values.password) ? (
              <p className={classes.extraTextPasswordGood}>
                {intl.formatMessage({
                  ...commonMessages['tooShortPass'],
                })}
              </p>
            ) : (
              <Typography
                className={classes.extraTextPassword}
                variant="caption"
              >
                {intl.formatMessage({ ...messages.PasswordTip })}
              </Typography>
            )
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPass((prev) => !prev)}
                  onMouseDown={handleMouseDownPassword}
                  className={classes.icon}
                >
                  {showPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* кнопка для регистрации */}
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          className={classes.submitButton}
        >
          <div className={classes.extraTextBtn}>
            {intl.formatMessage({ ...messages.register })}
          </div>
        </LoadingButton>
        <Typography
          component="span"
          className={classes.extraText}
          style={{ marginTop: '16px' }}
        >
          {intl.formatMessage({ ...messages.otherRegistration })}
        </Typography>
        <Grid
          container
          spacing={1}
          direction="column"
          style={{ marginTop: 5, marginBottom: 5 }}
        >
          <Grid
            item
            xs={15}
          >
            <GoogleSignIn onClick={onClose} />
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          className={classes.policy}
        >
          {intl.formatMessage({ ...messages.policy })}
          <Link
            to="/policy"
            onClick={() => onClose()}
          >
            {intl.formatMessage({ ...messages.terms })}
          </Link>{' '}
          {intl.formatMessage({ ...messages.policyEnd })}
        </Typography>
        <div className={classes.bottomText}>
          <Typography component="span">
            {intl.formatMessage({ ...messages.hasAcc })}
          </Typography>
          <Typography
            component="span"
            color="primary"
            onClick={() => setType('login')}
          >
            {intl.formatMessage({ ...messages.signin })}
          </Typography>
        </div>
      </form>
    </div>
  );
}

RegisterComponent.propTypes = {
  onSubmit: PropTypes.func,
  setType: PropTypes.func,
  intl: PropTypes.object,
  messages: PropTypes.object,
  onClose: PropTypes.func,
};

export default RegisterComponent;
