import axios from 'axios';
import { BASE_URL } from 'fsd/shared/consts/constants';
import {
  getRefreshTokenFromCookies,
  getTokenFromCookies,
  setTokenToCookies,
} from 'utils/cookies';

export const requester = axios.create({
  baseURL: BASE_URL,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

requester.interceptors.request.use(
  (config) => {
    const customConfig = config;
    const token = getTokenFromCookies();

    if (token) {
      customConfig.headers.Authorization = `Bearer ${token}`;
    }

    if (customConfig.data && customConfig.data.noAuth) {
      delete customConfig.data.noAuth;
      delete customConfig.headers.Authorization;
    }

    if (customConfig.data && customConfig.data.multi) {
      customConfig.headers['Content-Type'] = 'multipart/form-data';
      customConfig.headers.Accept = 'multipart/form-data';
    }

    if (customConfig.blob) {
      customConfig.responseType = 'blob';
      customConfig.exposedHeaders = ['Content-Disposition'];
    }

    return customConfig;
  },
  (error) => Promise.reject(error),
);

requester.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refresh = getRefreshTokenFromCookies();
        const newToken = await axios
          .post(`${BASE_URL}/auth/v1/refresh/`, { refresh })
          .then((res) => res.data.access);
        processQueue(null, newToken);
        setTokenToCookies(newToken);
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  },
);
