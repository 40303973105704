import React from 'react';
import { NavLink } from 'react-router-dom';

import { useStyles } from '../styles';

const ProductStateActive = ({
  getComponent,
  product,
  disableLink,
  onReview,
  isSelect,
  isMyProfile,
  haventImage,
  isDiscount,
}) => {
  const classes = useStyles();
  return (
    <NavLink
      className={classes.navWrapper}
      onClick={disableLink}
      to={`/products/${product?.id}`}
    >
      <div
        className={classes.image}
        style={{
          background: `url(${
            product?.first_photo ? product?.first_photo : haventImage
          }) no-repeat 50% 50%`,
        }}
      >
        {getComponent('getDiscount', isDiscount)}
        {getComponent('getOnReview', onReview)}
        {getComponent('getIsNotSelectProfile', !isSelect && !isMyProfile)}
      </div>
      {getComponent('getPriceAndTitle', !isSelect && !isMyProfile)}
    </NavLink>
  );
};

export default ProductStateActive;
