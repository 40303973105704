import GoogleAuth from 'containers/GoogleAuth/GoogleAuth';
import React from 'react';
import componentLoader from 'utils/componentLoader';

// layouts
// const WithSearchBar = React.lazy(() => componentLoader(() => import('layouts/WithSearchBar'), 1000))

const SimpleLayout = React.lazy(() => import('layouts/SimpleLayout'));

// pages
// const HomePage = React.lazy(() => componentLoader(() => import('pages/HomePage'), 1000))

const ProductPage = React.lazy(() =>
  componentLoader(() => import('pages/ProductPage'), 1000),
);

const UserPage = React.lazy(() =>
  componentLoader(() => import('pages/UserPage'), 1000),
);

const PostPage = React.lazy(() =>
  componentLoader(() => import('pages/PostPage'), 1000),
);

const FavoritesPage = React.lazy(() =>
  componentLoader(() => import('pages/FavoritesPage'), 1000),
);

const ProfilePage = React.lazy(() =>
  componentLoader(() => import('pages/ProfilePage'), 1000),
);

const ProductEditPage = React.lazy(() =>
  componentLoader(() => import('pages/ProductEditPage'), 1000),
);

const PolicyPage = React.lazy(() =>
  componentLoader(() => import('pages/PolicyPage'), 1000),
);

const CategoryPage = React.lazy(() =>
  componentLoader(() => import('pages/CategoryPage'), 1000),
);

const SitemapPage = React.lazy(() =>
  componentLoader(() => import('pages/Sitemap'), 1000),
);

const URLNotFound = React.lazy(() =>
  componentLoader(() => import('pages/URLNotFound'), 1000),
);

const AboutUs = React.lazy(() =>
  componentLoader(() => import('pages/AboutUs'), 1000),
);

const RequestForBusiness = React.lazy(() =>
  componentLoader(() => import('pages/RequestForBusiness'), 1000),
);

const BusinessAdvantages = React.lazy(() =>
  componentLoader(() => import('pages/BusinessAdvantages'), 1000),
);

const BusinessRegistration = React.lazy(() =>
  componentLoader(() => import('pages/BusinessRegistration'), 1000),
);

const BusinessProfile = React.lazy(() =>
  componentLoader(() => import('pages/ProfilePage'), 1000),
);

const BusinessProfileEdit = React.lazy(() =>
  componentLoader(() => import('business/pages/BusinessProfileEdit/ui'), 1000),
);

const BusinessUserProfile = React.lazy(() =>
  componentLoader(() => import('business/pages/BusinessProfile/ui'), 1000),
);

const BusinessProfileReviews = React.lazy(() =>
  componentLoader(
    () => import('business/pages/BusinessProfileReviews/ui'),
    1000,
  ),
);

const StatisticsPage = React.lazy(() =>
  componentLoader(() => import('pages/Statistics'), 1000),
);

const BalanceUpPage = React.lazy(() =>
  componentLoader(() => import('pages/BalanceUp'), 1000),
);

const WalletHistoryPage = React.lazy(() =>
  componentLoader(() => import('pages/PaymentHistory'), 1000),
);

const PurchasePage = React.lazy(() =>
  componentLoader(() => import('fsd/pages/PurchasePage/index.js'), 1000),
);

const ForbiddenProductList = React.lazy(() =>
  componentLoader(() => import('pages/BlogPages/ForbiddenProductsList'), 1000),
);

const BlogMainPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogMain'), 1000),
);

const BlogTelephonePage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogTelephone'), 1000),
);

const BlogLaptopPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogLaptop'), 1000),
);

const BlogAgrobazarPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogAgrobazar'), 1000),
);

const BlogEarningPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogEarning'), 1000),
);

const BlogTransportPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogTransport'), 1000),
);

const BlogJobPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogJob'), 1000),
);

const BlogDeceiversPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogDeceivers'), 1000),
);

const BlogFunctionalityPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogFunctionality'), 1000),
);

const BlogPhotoPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogPhoto'), 1000),
);

const BlogSalesPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogSales'), 1000),
);

const BlogAdsPage = React.lazy(() =>
  componentLoader(() => import('pages/Blog/BlogAds'), 1000),
);

const DevelopedPage = React.lazy(() =>
  componentLoader(() => import('pages/DevelopedPage'), 1000),
);

export const routes = [
  {
    path: '/',
    name: 'Main',
    component: SimpleLayout,
    layout: SimpleLayout,
    defaultPage: true,
    secure: false,
    exact: true,
  },
  {
    path: '/category/:category/:subcategory/:tripcategory',
    name: 'Category',
    component: CategoryPage,
    layout: SimpleLayout,
    defaultPage: true,
    secure: false,
    exact: true,
  },
  {
    path: '/category/:category/:subcategory',
    name: 'Category',
    component: CategoryPage,
    layout: SimpleLayout,
    defaultPage: true,
    secure: false,
    exact: true,
  },
  {
    path: '/category/:category',
    name: 'Category',
    component: CategoryPage,
    layout: SimpleLayout,
    defaultPage: true,
    secure: false,
    exact: true,
  },
  {
    path: '/products/:id',
    name: 'Product',
    component: ProductPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: false,
  },
  {
    path: '/link/share',
    name: 'Product',
    component: ProductPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/post',
    name: 'Post',
    component: PostPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/my-products/edit/:id',
    name: 'Product update',
    component: ProductEditPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: FavoritesPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/my-products',
    name: 'My products',
    component: ProfilePage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: ProfilePage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile',
    name: 'BusinessProfile',
    component: BusinessProfile,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/user/:id',
    name: 'UserPage',
    component: UserPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/b/:id',
    name: 'BusinessUserProfile',
    component: BusinessUserProfile,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/settings/password_change',
    name: 'Settings password change',
    component: ProfilePage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/settings/set_password',
    name: 'Settings set password',
    component: ProfilePage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/my-messages',
    name: 'My messages',
    component: ProfilePage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/edit',
    name: 'Edit',
    component: BusinessProfileEdit,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/reviews',
    name: 'Edit',
    component: BusinessProfileReviews,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/b/:id/reviews',
    name: 'Edit',
    component: BusinessProfileReviews,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/policy',
    name: 'Policy',
    component: PolicyPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: SitemapPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/about',
    name: 'About US',
    component: AboutUs,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/business-request',
    name: 'Business Request',
    component: RequestForBusiness,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/business-advantages',
    name: 'Business Advantages',
    component: BusinessAdvantages,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/business-registration',
    name: 'Business Registration',
    component: BusinessRegistration,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/statistics/:id',
    name: 'Business Statistics',
    component: StatisticsPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/balance-up',
    name: 'Balance Up',
    component: BalanceUpPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/wallet-history',
    name: 'Wallet History',
    component: WalletHistoryPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/purchase',
    name: 'Purchase Packets',
    component: PurchasePage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/profile/wallet',
    name: 'Wallet',
    component: ProfilePage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: true,
    exact: true,
  },
  {
    path: '/blog/forbidden-products',
    name: 'Forbidden Product List',
    component: ForbiddenProductList,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/main',
    name: 'Blog Main',
    component: BlogMainPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/telephone',
    name: 'Blog Telephone',
    component: BlogTelephonePage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/laptop',
    name: 'Blog Laptop',
    component: BlogLaptopPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/agrobazar',
    name: 'Blog Laptop',
    component: BlogAgrobazarPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/earning',
    name: 'Blog Earning',
    component: BlogEarningPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/transport',
    name: 'Blog Transport',
    component: BlogTransportPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/job',
    name: 'Blog Job',
    component: BlogJobPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/deceivers',
    name: 'Blog Deceivers',
    component: BlogDeceiversPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/functionality',
    name: 'Blog Functionality',
    component: BlogFunctionalityPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/photo',
    name: 'Blog Photo',
    component: BlogPhotoPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/sales',
    name: 'Blog Sales',
    component: BlogSalesPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/blog/ads',
    name: 'Blog Sales',
    component: BlogAdsPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/qa',
    name: 'Questions And Answers',
    component: DevelopedPage,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '/google/:code',
    name: 'googleAuth',
    component: GoogleAuth,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
  {
    path: '*',
    name: 'URLNotFound',
    component: URLNotFound,
    layout: SimpleLayout,
    defaultPage: false,
    secure: false,
    exact: true,
  },
];
