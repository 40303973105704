export const ACTION_TYPES = {
  GET_PRODUCT_LIST: 'GET_PRODUCT_LIST',
  GET_PRODUCT_LIST_SHORT: 'GET_PRODUCT_LIST_SHORT',
  GET_PRODUCT_INFO: 'GET_PRODUCT_INFO',
  GET_FAVORITES: 'GET_FAVORITES',
  GET_PERSONAL_PRODUCTS: 'GET_PERSONAL_PRODUCTS',
  UPVOTE_PRODUCT: 'UPVOTE_PRODUCT',
  GET_ADS: 'GET_ADS',
  OPEN_MODAL: 'OPEN_MODAL',
  GET_CLAIM_CATEGORIES: 'GET_CLAIM_CATEGORIES',
  SEND_CLAIM_TO_PRODUCT: 'SEND_CLAIM_TO_PRODUCT',
  SET_PREV_PARAMS: 'SET_PREV_PARAMS',
};

export const ENDPOINTS = {
  PRODUCTS: '/product/v1/products/',
  PRODUCTS_LIST: '/product/v1/product/list/',
  DEV_LIST: '/product/v1/products/',
  FAVORITES: '/product/v1/personal/favorites/',
  PERSONAL: '/product/v1/personal/products/',
  ADS: '/ads/',
  COUNT_CLICK: '/ads/count-click/',
  IS_OPEN_MODAL: '/business/v1/every_three_days/',
  PRODUCT_CLAIMS: '/product/v1/products/claim/',
  PRODUCT_CLAIM: (id) => `/product/v1/products/${id}/claim/`,
};
