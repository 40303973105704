/*
 *
 * AdsPage reducer
 *
 */
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { getStateValue } from 'states/helpers';
import { actions } from './actions';

export const initialState = {
  info: null,
  businessProfile: null,
  error: null,
};

const loadingInitialState = {
  isLoadingInfo: false,
  isLoadingBusinessProfile: false,
};

const loading = handleActions(
  {
    [actions.getProfileRequest]: (state) => ({
      ...state,
      isLoadingInfo: true,
    }),
    [actions.getProfileSuccess]: (state) => ({
      ...state,
      isLoadingInfo: false,
    }),
    [actions.getProfileFail]: (state) => ({
      ...state,
      isLoadingInfo: false,
    }),
    [actions.getBusinessProfileRequest]: (state) => ({
      ...state,
      isLoadingBusinessProfile: true,
    }),
    [actions.getBusinessProfileSuccess]: (state) => ({
      ...state,
      isLoadingBusinessProfile: false,
    }),
    [actions.getBusinessProfileFail]: (state) => ({
      ...state,
      isLoadingBusinessProfile: false,
    }),
  },
  loadingInitialState,
);

const data = handleActions(
  {
    [actions.getProfileSuccess]: (state, { payload }) => ({
      ...state,
      info: {
        ...payload,
        photo: payload.photo
          ? payload.photo.replace('http', 'https')
          : payload.photo,
      },
      error: null,
    }),
    [actions.getBusinessProfileSuccess]: (state, { payload }) => ({
      ...state,
      businessProfile: payload,
      error: null,
    }),
    [actions.getProfileFail]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [actions.getBusinessProfileFail]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
  },
  initialState,
);

export const reducer = combineReducers({
  data,
  loading,
});

export const key = 'profile';

export const profileSelectors = {
  info: (state) => getStateValue(state, key, 'info'),
  businessProfile: (state) => getStateValue(state, key, 'businessProfile'),
  isLoadingInfo: (state) =>
    getStateValue(state, key, 'loading', 'isLoadingInfo', true, false),
  isLoadingBusinessProfile: (state) =>
    getStateValue(
      state,
      key,
      'loading',
      'isLoadingBusinessProfile',
      true,
      false,
    ),
  error: (state) => getStateValue(state, key, 'error'),
};
