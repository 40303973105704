import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    marginTop: 10,
  },
  input: {
    width: '100%',
    padding: '7px 10px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    cursor: 'pointer',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& svg': {
      transition: '500ms',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: theme.spacing(2.5),
    },

    '&:hover': {
      borderColor: theme.palette.primary.main,

      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  },
  fullPage: {
    position: 'absolute !important',
    top: '-87px !important',
    left: '-1px !important',
    right: '0 !important',
    bottom: '0 !important',
    zIndex: '99 !important',
    width: '100.3% !important',
    height: '100vh !important',
    maxHeight: '100vh !important',
    boxShadow: 'none !important',
  },
  menu: {
    paddingTop: '24px',
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
    background: '#fafafa',
    position: 'absolute',
    maxHeight: '50vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    top: 37,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 1200,
    scrollbarWidth: 'thin',
    boxShadow: 'none !important',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 8,
    },

    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#bbb',
      borderRadius: 5,
    },

    '&::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
    },
    '& ul': {
      border: '1px solid #D2D2D2',
      background: '#fff',
    },
  },
  opened: {
    borderColor: theme.palette.primary.main,

    '& svg': {
      fill: theme.palette.primary.main,
      transform: 'rotate(180deg)',
    },
  },
  backIcon: {
    fill: theme.palette.primary.main,
    fontSize: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  childrenIcon: {
    fill: '#B5B5B5',
  },
  listItemText: {
    color: '#282828',
    fontWeight: 600,
    fontSize: 14,
  },
  selected: {
    color: theme.palette.primary.main,
  },
  icon: {
    marginRight: 10,
    display: 'inline-block',
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
    '& h2': {
      color: '#333',
      fontSize: 24,
      fontWeight: 500,
    },

    '& div': {
      width: 31,
    },

    '& button': {
      padding: 7,
    },

    '& button svg': {
      fill: '#333',
      width: 24,
      height: 24,
    },
  },
  mobileList: {
    height: '77vh !important',
    overflowY: 'auto',
    padding: '0 !important',
    '& .MuiListItem-root': {
      height: 77,
    },
  },
  withDivider: {
    '& .MuiListItem-root': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  rootListItem: {
    padding: 0,
  },
}));
