import { requester } from 'services/requester';

export const getData = (...args) =>
  requester.get(...args).then((res) => res.data);
export const postData = (...args) =>
  requester.post(...args).then((res) => res.data);
export const patchData = (...args) =>
  requester.patch(...args).then((res) => res.data);
export const putData = (...args) =>
  requester.put(...args).then((res) => res.data);
export const deleteData = (...args) =>
  requester.delete(...args).then((res) => res.data);
