import React, { useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';
import {
  CardActions,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';

import { useStyles, CustomSwitch } from '../styles';
import DeleteIcon from 'assets/images/deletes.svg';
import edit from 'assets/images/editBlue.svg';
import chart from 'assets/images/chart.svg';
import plus from 'assets/images/plus_actions.svg';
import { useOutsideClick } from 'hooks/useOutSideClick';

import ShareIcon from 'assets/images/BusinessIcon/navigate/share-icon.svg';
import ModalCopyLinks from './Modals/ModalCopyLinks';
import ModalAddInCatalog from './Modals/ModalAddInCatalog';

const EditCard = (props) => {
  const {
    intl,
    product,
    messages,
    editCard,
    checkHandler,
    checked,
    handleAgree,
    open,
    setOpen,
    handleClick,
    isMyProfile,
    onReview,
    onUser,
  } = props;
  const classes = useStyles();
  const [isOpenCopyLinks, setIsOpenCopyLinks] = useState(false);
  const [isOpenAddInCatalog, setIsOpenAddInCatalog] = useState(false);
  const editCardRef = useRef(null);
  useOutsideClick(editCardRef, handleClick, editCard);
  return (
    <Paper
      ref={editCardRef}
      className={editCard ? classes.menuShow : classes.menuHide}
    >
      <MenuList>
        {isMyProfile && !onReview && (
          <>
            <MenuItem
              onClick={() => setIsOpenCopyLinks(true)}
              className={classes.item}
            >
              {/* FIX_ME: Нужен перевод */}
              <ListItemIcon>
                <img
                  src={ShareIcon}
                  alt="Поделиться"
                />
              </ListItemIcon>
              <ListItemText>Поделиться</ListItemText>
            </MenuItem>
            <MenuItem
              className={classes.item}
              onClick={() => {
                checkHandler();
                handleClick();
              }}
            >
              <ListItemIcon>
                <CustomSwitch
                  checked={!checked}
                  color="primary"
                />{' '}
              </ListItemIcon>
              <ListItemText>
                {!checked
                  ? intl.formatMessage({ ...messages.activationMessage })
                  : intl.formatMessage({ ...messages.deactivationMessage })}
              </ListItemText>
            </MenuItem>
            {product?.user.is_business &&
              product?.user.request_status !== 'expired' && (
                <MenuItem className={classes.item}>
                  <NavLink
                    className={classes.item}
                    to={`/profile/statistics/${product?.id}`}
                  >
                    <ListItemIcon>
                      <img
                        src={chart}
                        alt="График"
                      />
                    </ListItemIcon>
                    <ListItemText>Статистика</ListItemText>
                  </NavLink>
                </MenuItem>
              )}
            <MenuItem className={classes.item}>
              <NavLink
                className={classes.item}
                to={`/profile/my-products/edit/${product?.id}/`}
              >
                <ListItemIcon>
                  <img
                    src={edit}
                    alt="Изменить"
                  />
                </ListItemIcon>
                <ListItemText>
                  {intl?.formatMessage({
                    ...messages.activatedMessage,
                  })}
                </ListItemText>
              </NavLink>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpen({
                  title: intl?.formatMessage({
                    ...messages.deleteProductAlertTitle,
                  }),
                  description: intl?.formatMessage({
                    ...messages.deleteProductAlertDescription,
                  }),
                  method: 'delete',
                });
              }}
              className={classes.item}
            >
              <ListItemIcon>
                {/* FIX_ME: Нужен перевод */}
                <img
                  alt="Удалить"
                  src={DeleteIcon}
                />
              </ListItemIcon>
              <ListItemText className={classes.remove}>
                {intl?.formatMessage({
                  ...messages.delationProduct,
                })}
              </ListItemText>
            </MenuItem>
          </>
        )}
      </MenuList>

      <AlertDialog
        open={!!open}
        title={open ? open?.title : ''}
        description={open ? open?.description : ''}
        onClose={() => setOpen(null)}
        onAgree={() => handleAgree(open?.method)}
      />
      <ModalCopyLinks
        productId={product.id}
        open={isOpenCopyLinks}
        handleClose={() => setIsOpenCopyLinks(!isOpenCopyLinks)}
      />
      <ModalAddInCatalog
        intl={intl}
        product={product}
        open={isOpenAddInCatalog}
        handleClose={() => setIsOpenAddInCatalog(!isOpenAddInCatalog)}
      />
    </Paper>
  );
};

export default React.memo(EditCard);
