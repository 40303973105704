import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData } from 'business/config/api/fetcher';

export const fetchBusinessProfile = createAsyncThunk(
  'business/fetchProfile',
  async (businessId) => {
    if (!businessId) throw new Error('Business ID is required');
    const response = await getData(`business/v1/businesses/${businessId}`);
    return response;
  },
);

const businessSlice = createSlice({
  name: 'business',
  initialState: {
    businessProfile: null,
    loading: true,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBusinessProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.businessProfile = action.payload;
      })
      .addCase(fetchBusinessProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default businessSlice.reducer;
