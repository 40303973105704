import React from 'react';
import { useStyles } from '../styles';

const types = {
  getDiscount: 'getDiscount',
  getIsSelectProfile: 'getIsSelectProfile',
  getIsNotSelectProfile: 'getIsNotSelectProfile',
  getPriceAndTitle: 'getPriceAndTitle',
  getOnReview: 'getOnReview',
  isReview: 'on_review',
};

const ProductStateNotActive = ({
  onReview,
  product,
  haventImage,
  alert,
  isSelect,
  isMyProfile,
  getComponent,
  isDiscount,
}) => {
  const classes = useStyles();

  return (
    <>
      <div
        onClick={
          onReview
            ? () => {
                alert.error('Это объявление неактивное');
              }
            : undefined
        }
        className={classes.image}
        style={{
          background: `url(${
            product?.first_photo ? product?.first_photo : haventImage
          }) no-repeat 50% 50%`,
        }}
      >
        {getComponent(types.getDiscount, isDiscount)}
        {product?.state === types.isReview &&
          getComponent(types.getOnReview, onReview)}
        {getComponent(types.getIsSelectProfile, isSelect && isMyProfile)}
        {getComponent(types.getIsNotSelectProfile, !isSelect && !isMyProfile)}
      </div>
      {getComponent(types.getPriceAndTitle, !isSelect && !isMyProfile)}
    </>
  );
};

export default ProductStateNotActive;
